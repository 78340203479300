<template>
    <div class="celebrate-content">
        <div class="celebrate-title">819中国医师节学习联盟直播活动抽奖</div>
        <div class="celebrate-main">
            <div class="celebrate-item">
                <el-button type="primary" class="btn" @click="lucky(1)">{{ type==1?'正在抽奖中...':'面值一千元代金券' }}</el-button>
                <h3>获奖名单</h3>
                <ul style="height: 50px">
                    <li v-for="(item,index) in prize1">{{ item.phone }}</li>
                </ul>
            </div>
            <div class="celebrate-item">
                <el-button type="primary" class="btn" @click="lucky(2)">{{ type==2?'正在抽奖中...':'面值一千元代金券' }}</el-button>
                <h3>获奖名单</h3>
                <ul style="height: 50px">
                    <li v-for="(item,index) in prize2">{{ item.phone }}</li>
                </ul>
            </div>
            <div class="celebrate-item">
                <el-button type="primary" class="btn" @click="lucky(3)">{{ type==3?'正在抽奖中...':'会员年卡一年' }}</el-button>
                <h3>获奖名单</h3>
                <ul>
                    <li v-for="(item,index) in prize3">{{ item.phone }}</li>
                </ul>
            </div>
			<!--优选科普视频内容U盘-->
           <!-- <div class="celebrate-item">
                <el-button type="primary" class="btn" @click="lucky(4)">{{ type==4?'正在抽奖中...':'内容U盘' }}</el-button>
                <h3>获奖名单</h3>
                <ul style="justify-content: left">
                    <li v-for="(item,index) in prize4">{{ item.phone }}</li>
                </ul>
            </div> -->
        </div>



        <el-dialog
                :title="type==1?'面值一千元代金券':type==2?'面值一千元代金券':type==3?'会员年卡一年':type==4?'内容U盘':'抽奖滚动'"
                :visible.sync="typeVisible"
                width="30%"
                :modal="false"
                :show-close="false"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                :before-close="handleClose">
            <div style="text-align: center">
                <div class="prizeNum">
                    <template v-if="type == 1">
                        {{ prizeNum1 }}
                    </template>
                    <template v-if="type == 2">
                        {{ prizeNum2 }}
                    </template>
					<template v-if="type == 3">
					    {{ prizeNum3[0] }}
					</template>
                    <!-- <template v-if="type == 3">
                        <div v-for="(item,index) in prizeNum3">{{ item }}</div>
                    </template>
                    <template v-if="type == 4">
                        <div v-for="(item,index) in prizeNum4">{{ item }}</div>
                    </template> -->
                </div>
                <el-button type="primary" @click="stopLucky()">停止抽奖</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                typeVisible: false,
                type: 0,
                prize1: [],
                prize2: [],
                prize3: [],
                prize4: [],

                prizeNum1:'***********',
                prizeNum2:'***********',
                prizeNum3: [
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                ],
                prizeNum4: [
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                ],
                times: null
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            handleClose(done) {
                clearInterval(this.times)
                done();
            },
            getList() {
                this.$axios.post(this.apiUrl.luckDrawYear_All, {}).then(res => {
                    // console.log(res)
                    this.prize1 = [];
                    this.prize2 = [];
                    this.prize3 = [];
                    this.prize4 = [];
                    res.data.map(item => {
                        if(item.type == 1) {
                            this.prize1.push(item)
                        } else if(item.type == 2) {
                            this.prize2.push(item)
                        } else if(item.type == 3) {
                            this.prize3.push(item)
                        } else if(item.type == 4) {
                            this.prize4.push(item)
                        }
                    })
                })
            },
            lucky(type) {
                this.$axios.post(this.apiUrl.luckDrawYear2, {
                    type
                }).then(res2 => {
                    if(res2.code == 1) {
                        if(type == this.type) {
                            this.stopLucky();
                        }else if(this.type>0 && this.type != type){
                            this.$message.warning(`请先停止${this.type}等奖抽奖`)
                        }else{
                            this.typeVisible = true;
                            this.type = type;
                            this.times = setInterval(()=> {
                                if(this.type == 1 || this.type == 2) {
                                    let arr = []
                                    arr[0] = 1;
                                    let two = [3,4,5,7,8,9];
                                    arr[1] = two[Math.floor((Math.random()*two.length))];
                                    arr[2] = Math.floor(Math.random()*10);
                                    arr[3]=arr[4]=arr[5]=arr[6] = '*';
                                    arr[7] = Math.floor(Math.random()*10);
                                    arr[8] = Math.floor(Math.random()*10);
                                    arr[9] = Math.floor(Math.random()*10);
                                    arr[10] = Math.floor(Math.random()*10);
                                    this.type == 1?this.prizeNum1 = arr.join(''):this.prizeNum2 = arr.join('')
                                }else {
                                    let arr = []
                                    for (let i = 0;i<10;i++) {
                                        let obj = []
                                        obj[0] = 1;
                                        let two = [3,4,5,7,8,9];
                                        obj[1] = two[Math.floor((Math.random()*two.length))];
                                        obj[2] = Math.floor(Math.random()*10);
                                        obj[3]=obj[4]=obj[5]=obj[6] = '*';
                                        obj[7] = Math.floor(Math.random()*10);
                                        obj[8] = Math.floor(Math.random()*10);
                                        obj[9] = Math.floor(Math.random()*10);
                                        obj[10] = Math.floor(Math.random()*10);
                                        arr.push(obj.join(''))
                                    }
                                    this.type == 3?this.prizeNum3 = arr:this.prizeNum4 = arr
                                }
                            },100)
                        }
                    }else {
                        this.$message.warning(res2.msg)
                    }
                })
            },
            stopLucky() {
                this.$axios.post(this.apiUrl.luckDrawYear, {
                    type: this.type
                }).then(res => {
                    if(res.code == 1) {
                        this.getList();
                    } else {
                        this.$message.warning(res.msg)
                    }
                    if(this.type == 1) {
                        this.prizeNum1 = '***********'
                    }else if(this.type == 2) {
                        this.prizeNum2 = '***********'
                    }else if(this.type == 3) {
                        this.prizeNum3 = [
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                        ]
                    }else if(this.type == 4) {
                        this.prizeNum4 = [
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                        ]
                    }
                    this.type = 0;
                    this.typeVisible = false;
                    clearInterval(this.times);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .celebrate-content{
        color: #fff;
        background: url("../../assets/img/celebrate.jpg") no-repeat;
        background-size: cover;
		background-position: bottom;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        .btn{
            font-size: 32px;
        }
        .celebrate-title{
            font-size: 60px;
            color: #fff;
            text-align: center;
            padding-top: 20px;
			text-shadow: 0 2px 5px #fff;
        }
        .celebrate-main{
            display: flex;
            padding: 30px;
            flex-wrap: wrap;
        }
        .celebrate-item{
            width: 50%;
            text-align: center;
            margin-bottom: 60px;
            box-sizing: border-box;
			border-right: 2px solid #fff;
            &:nth-child(2n){
                // padding-left: 20px;
				border-left: 2px solid #fff;
				border-right: 0;
            }
            h3{
                font-size: 50px;
                margin-top: 10px;
            }
            ul{
                margin-top: 10px;
                display: flex;
                flex-wrap: wrap;
                text-align: center;
                align-items: center;
                align-content: center;
                justify-content: center;
                li{
                    display: inline-block;
                    font-size: 26px;
                    color: #f5f5f5;
                    width: 20%;
                }
            }
        }
		.celebrate-item:nth-child(3){
			width: 100%;
			border-right: 0px solid #fff;
		}
    }
    .prizeNum{
        font-size: 30px;
    }
</style>
